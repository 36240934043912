const DEV = process.env.NODE_ENV === 'development';

import Swiper, { EffectFade, Thumbs, HashNavigation, Navigation } from 'swiper';
import 'swiper/css';

//import imagesLoaded from 'imagesloaded';

export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('init common');

		//Dark mode storage
		let isDarkMode = localStorage.getItem('theme') || '';
		if (isDarkMode) document.body.classList.add(isDarkMode);

		// Remove no-js
		document.documentElement.classList.remove('no-js');
		document.documentElement.classList.add('js');

		// Test touch
		if (!('ontouchstart' in document.documentElement)) {
			document.documentElement.classList.add('no-touch');
		} else {
			document.documentElement.classList.add('touch');
		}

		function hideLoader() {
			document.body.classList.add('loaded');
		}

		jQuery(document).ready(function ($) {
			setTimeout(() => {
				hideLoader();
			}, 1000);

			let $projectOverlay = $('.project_overlay'),
				$projectContainer = $('.project_overlay_content');

			if ($projectOverlay.length <= 0) setProjectSwipers();

			$('.js-toggle-darkmode').bind('click', function (e) {
				e.preventDefault();
				$('body').toggleClass('dark-mode');
				let theme = localStorage.getItem('theme');
				if (theme && theme === 'dark-mode') {
					localStorage.setItem('theme', '');
				} else {
					localStorage.setItem('theme', 'dark-mode');
				}
			});

			$('.js-toggle-info a').bind('click', function (e) {
				e.preventDefault();
				$('body').toggleClass('info--open');
			});

			$('.js-open-project').bind('click', function (e) {
				e.preventDefault();
				var url = $(this).attr('href'),
					slide = url.split('#slide').pop();
				if (url) openProject(url, slide);
			});

			$('.js-close-project').bind('click', function (e) {
				e.preventDefault();
				closeProject();
			});

			function openProject(url, initialSlide) {
				$projectContainer.empty().removeClass('is-loaded');
				$projectOverlay.addClass('open');
				$projectContainer.load(url + ' #content', function () {
					setProjectSwipers(initialSlide);
				});
			}

			function closeProject() {
				if ($projectOverlay) $projectOverlay.removeClass('open');
			}

			function setProjectSwipers(initialSlide = 0) {
				const swiperGalleryThumbs = new Swiper(
					'.gallery_thumbs_swiper',
					{
						slidesPerView: 8,
						slideToClickedSlide: true,
						spaceBetween: 2,
						breakpoints: {
							744: {
								slidesPerView: 12,
							},
						},
					}
				);

				const swiperGalleryMain = new Swiper('.gallery_main_swiper', {
					modules: [EffectFade, Thumbs, HashNavigation, Navigation],
					effect: 'fade',
					speed: 700,
					allowTouchMove: false,
					simulateTouch: false,
					initialSlide: initialSlide,
					hashNavigation: {
						watchState: true,
					},
					navigation: {
						nextEl: '.main_next',
						prevEl: '.main_prev',
					},
					thumbs: {
						swiper: swiperGalleryThumbs,
					},
					on: {
						afterInit: (swiper) => {
							swiper.thumbs.swiper.params.slideToClickedSlide = true;
							swiper.thumbs.swiper.update();
							if ($projectContainer)
								$projectContainer.addClass('is-loaded');
						},
						realIndexChange: (swiper) => {
							swiper.thumbs.swiper.slideToLoop(swiper.realIndex);
						},
					},
				});
			}
		});
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize common');
	},
};
