import Swiper, { FreeMode, Mousewheel } from 'swiper';
import 'swiper/css';

export default {
	init() {
		console.log('commission');

		jQuery(document).ready(function ($) {
			$('.overview_swiper').each(function () {
				new Swiper(this, {
					modules: [FreeMode, Mousewheel],
					slidesPerView: 'auto',
					spaceBetween: 2,
					centerInsufficientSlides: true,
					resistanceRatio: 0,
					mousewheel: true,
					freeMode: true,
				});
			});
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
