import imagesLoaded from 'imagesloaded';

export default {
	init() {
		console.log('home');

		jQuery(document).ready(function ($) {
			var swiper1 = $('.hp_swiper')[0],
				swiper2 = $('.hp_swiper')[1],
				timePassed = false,
				showIntro = $('.loading_intro').length > 0;

			setTimeout(() => {
				timePassed = true;
			}, 2000);

			imagesLoaded('.hp_swiper', function () {
				if (showIntro) {
					if (timePassed) {
						startIntro();
					} else {
						setTimeout(() => {
							startIntro();
						}, 2000);
					}
				} else {
					centerSwipersScrollbar();
				}
			});

			function startIntro() {
				document.body.classList.add('hp-loaded');
				/* setTimeout(() => {
					centerSwipersScrollbar();
				}, 1700); */
			}

			function centerSwipersScrollbar() {
				swiper1.scrollTop =
					(swiper1.scrollHeight - swiper1.clientHeight) / 2;
				swiper2.scrollTop =
					(swiper2.scrollHeight - swiper2.clientHeight) / 2;
			}

			const $hpContent = $('.hp_content');

			$(document).on('input', '#zoomer', function () {
				$hpContent.css('width', $(this).val() * 100 + '%');
			});
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
